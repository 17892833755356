
import ReturnModal from '@razlet/sdk/lib/components/asb/order/search/modal';

export default {
  name: 'info-buttons',
  components: {
    ReturnModal,
  },
  data() {
    return {
      isReturnWindowOpened: false,
      typeReturn: null,
    };
  },
  computed: {
    questions() {
      return [
        {
          icon: 'rz-icon-ticket-check',
          text: this.$t('rkg.infoButtons[0]'),
          click: 'findOrder',
        },
        {
          icon: 'rz-icon-ticket-return',
          text: this.$t('rkg.infoButtons[1]'),
          click: 'returnTicket',
        },
        {
          icon: 'rz-icon-pass',
          text: this.$t('rkg.infoButtons[2]'),
          click: 'exchangePass',
        },
      ];
    },
  },
  methods: {
    actionCard(action) {
      this[action]();
    },
    returnTicket() {
      if (this.isAgent) {
        this.$router.push({ name: 'account-requests-create', query: { template: 'empty', types: 'tickets' } });
      } else {
        this.typeReturn = 'exchange';
        this.isReturnWindowOpened = true;
      }
    },
    findOrder() {
      this.typeReturn = 'search';
      this.isReturnWindowOpened = true;
    },
    exchangePass() {
      if (this.isAgent) {
        this.$router.push({ name: 'account-requests-create', query: { template: 'empty', types: 'tickets' } });
      } else {
        this.typeReturn = 'exchangePass';
        this.isReturnWindowOpened = true;
      }
    },
  },
};
